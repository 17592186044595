import { useState } from 'react';
import './OnlineNotiseApp.css';
import './handlers/OnlineSliderAnimation.css';
import OnlineSliderAnimation from './handlers/OnlineSliderAnimation';
import AudioPlayer from '../../components/web-aplications/web-notise/components/handlers/AudioPlayer';
import AnimationUpdate from '../../components/other/animation-update/Animation-update';


const OnlineNotiseApp = () => {
    const dataUrl = "http://dev-khlystikam.ru/php/web-notise/notise-table-online.json";
    const [reloadAnimationComp, setReloadAnimationComp] = useState<any>(<AnimationUpdate />);

    const reloudAnimatio = () => {
        setReloadAnimationComp(undefined)
    }

    setTimeout( reloudAnimatio, 2000);

    return (
        <div className="online-notise-app-background-1">
            { reloadAnimationComp }
            <OnlineSliderAnimation dataUrl={ dataUrl  }/>
            <AudioPlayer/>
        </div>
    )
}
  
export default OnlineNotiseApp;