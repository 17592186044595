import './animationUpdate.css';

const AnimationUpdate = () => {

    return (
        <div className="animation-update-box">
            <div className="loader"></div>
        </div>
    );
}

export default AnimationUpdate;