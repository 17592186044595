import './MainApp.css';
import { useState } from 'react';
import Header from "./components/app-layers/header/header";
import LeftSidebar from "./components/app-layers/left-sidebar/left-sidebar";
import RenderRouters from './components/routers/router';
import BurgerMenu from './components/app-layers/header/burger-menu';

function MainApp() {
  const [burgerMenuOpen, setBurgerMenuOpen] = useState<any>(undefined);
  const [indexBurgerMenu, setIndexBurgerMenu] = useState<boolean>(false);
  const [imgBurgerMenu, setImgBurgerMenu] = useState<string>("img-burger-arraw__open");

	function openBurger() {
    if(indexBurgerMenu === false){
      setBurgerMenuOpen(<BurgerMenu />);
      setImgBurgerMenu("img-burger-arraw__close");
      setIndexBurgerMenu(true);
    } else {
      setBurgerMenuOpen(undefined);
      setImgBurgerMenu("img-burger-arraw__open");
      setIndexBurgerMenu(false);
    }
  };


  return (
    <div className="MainApp">
      <LeftSidebar />
        <div className="right-side">
          <Header />
          <div className='aplications-window'>
            <RenderRouters />
          </div>
        </div>
        { burgerMenuOpen }
        <div className="burger-menu-box">
          <button className="button-burger-menu" onClick={ openBurger  }>
            <img className={ imgBurgerMenu } src="../../../assets/burger-menu/back.png" alt="burger menu" />
          </button>
        </div>
    </div>
  );

}

export default MainApp;
