import React, { useState } from 'react';
import './edite-color-site.css';

interface ToggleSwitchProps {
    label?: string;
}

const EditeColorSite: React.FC<ToggleSwitchProps> = ({ label }) => {
    let onChange: (newState: boolean) => void;

    const [isOn, setIsOn] = useState(false);
    const [mainColorBackground, setMainColorBackground] = useState('#F8F9FA');
    const [mainColorFont, setMainColorFont] = useState('#3a3a3a');
    const [leftbarColorBackground, setLeftbarColorBackground] = useState('#efefef');
    const [mainColorBackgroundBox, setMainColorBackgroundBox] = useState('#fff');
    const [myPhilosophyRandomColor, setMyPhilosophyRandomColor] = useState('#818181');
    const [socialNameColor, setSocialNameColor] = useState('#00000066');
    const [socialNameColorHover, setSocialNameColorHover] = useState('#0000009e');


    const changeColor = () => {
        const newColorMainColorBackground = mainColorBackground === '#F8F9FA' ? '#333' : '#F8F9FA';
        const newMainColorFont = mainColorFont === '#3a3a3a' ? '#fff' : '#3a3a3a';
        const newLeftbarColorBackground = leftbarColorBackground === '#efefef' ? '#454545' : '#efefef';
        const newMainColorBackgroundBox = mainColorBackgroundBox === '#fff' ? '#454545' : '#fff';
        const newMyPhilosophyRandomColor = myPhilosophyRandomColor === '#818181' ? '#fff' : '#818181';
        const newSocialNameColor = socialNameColor === '#00000066' ? '#fff' : '#00000066';
        const newSocialNameColorHover = socialNameColorHover === '#0000009e' ? '#fff' : '#0000009e';


        setMainColorBackground(newColorMainColorBackground);
        setMainColorFont(newMainColorFont);
        setLeftbarColorBackground(newLeftbarColorBackground);
        setMainColorBackgroundBox(newMainColorBackgroundBox);
        setMyPhilosophyRandomColor(newMyPhilosophyRandomColor);
        setSocialNameColor(newSocialNameColor);
        setSocialNameColorHover(newSocialNameColorHover);

        setIsOn(!isOn);
            if (onChange) {
            onChange(!isOn);
        }

        document.documentElement.style.setProperty('--main-color-background', newColorMainColorBackground);
        document.documentElement.style.setProperty('--main-color-font', newMainColorFont);
        document.documentElement.style.setProperty('--leftbar-color-background', newLeftbarColorBackground);
        document.documentElement.style.setProperty('--main-color-background-box', newMainColorBackgroundBox);
        document.documentElement.style.setProperty('--my-philosophy-random-color', newMyPhilosophyRandomColor);
        document.documentElement.style.setProperty('--social-name-color', newSocialNameColor);
    }

    return (
        <div className="toggle-switch">
            {label && <span className="toggle-switch-label">{label}</span>}
                <div
                    className={`switch ${isOn ? 'switch-on' : 'switch-off'}`}
                    onClick={changeColor}
                >
                <div
                    className= "switch-handle"
                />
            </div>
        </div>
    );
};

export default EditeColorSite;