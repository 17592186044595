import './header.css';
import NewsJsonGet from './news-get-json';
import EditeColorSite from './Edite-color-site';

const Header:React.FC = () => {
	return (
		<header className='header'>
			<div className="news-web-dev">
				<NewsJsonGet />
			</div>
			<div className="second-block"></div>
			<div className="third-block">
				<EditeColorSite label="Dark Mode" />
			</div>
		</header>
	);
};

export default Header;